import underwriter from "../../assets/card-images/Underwriter+.png";
import eventImpact from "../../assets/card-images/EventImpact.png";
import portfolioInsight from "../../assets/card-images/PortfolioInsight.png";
import geoPortal from "../../assets/card-images/GeoPortal.png";
import portfolioUpload from "../../assets/card-images/PortfolioUpload.png";
import admin from "../../assets/card-images/Admin.png";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import SecurityIcon from "@material-ui/icons/Security";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PollIcon from "@material-ui/icons/Poll";
import PublicIcon from "@material-ui/icons/Public";
import SettingsIcon from "@material-ui/icons/Settings";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const AppAccessList = {
  underwriter: {
    icon: ReportProblemIcon,
    image: underwriter,
    title: "Underwriter+",
    targetUrl: `${window.location.origin}/uw`,
    hasClientGroup: true,
    filterable: true,
    hasReceiver: true,
    appId: "1a4729a5-ce9e-42d1-b983-d95bf72dce40",
    description:
      "Make more profitable underwriting decisions with instant access to accumulation, hazard, guideline and loss information.",
  },
  eventImpact: {
    icon: SecurityIcon,
    image: eventImpact,
    title: "Event Impact",
    targetUrl: `${window.location.origin}/ei`,
    hasClientGroup: true,
    filterable: true,
    hasReceiver: true,
    appId: "0d9ded9a-be7c-4508-b708-6083c05fe74b",
    description:
      "Leverage the power of our real-time event feeds across a range of natural perils.  Quickly identify events of consequence, view the potential impact and respond quickly.",
  },
  portfolioInsight: {
    icon: PollIcon,
    image: portfolioInsight,
    title: "Portfolio Insight",
    targetUrl: `${window.location.origin}/ei/pi`,
    hasClientGroup: true,
    filterable: true,
    hasReceiver: true,
    appId: "99782aee-fc84-4973-93c3-7c5a8f014bde",
    description:
      "Utilize our best-in-class mapping experience to generate detailed analyses across multiple event layers and portfolios of interest.",
  },
  portfolioUpload: {
    icon: CloudUploadIcon,
    image: portfolioUpload,
    title: "Portfolio Upload Tool",
    targetUrl: `${window.location.origin}/portfoliouploadtool`,
    hasClientGroup: false,
    filterable: false,
    hasReceiver: false,
    description:
      "Upload and manage your own portfolio data loads into the platform.  Utilize custom templates for data mapping to streamline loading and validation.",
  },
  // modelDataUpload: {
  //   icon: CloudUploadIcon,
  //   image: admin,
  //   title: "Model Data Upload Tool",
  //   description:
  //     "Leverage the power of our real-time event feeds across a range of natural perils.  Quickly identify events of consequence, viewthe potential impact and respond quickly.",
  // },

  support: {
    icon: LiveHelpIcon,
    image: admin,
    title: "Support",
    hasClientGroup: false,
    email:
      "AdvantagePointSupport@guycarp.com",
    description:
      "Contact support with any questions you have or issues you run into.",
  },
};

export default AppAccessList;
